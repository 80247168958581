import './Login.css';
import { Spin,Alert } from "antd";
import React, { useState } from "react";

const LoadingSpinner = (props) => {
    const [tip, setTip] = useState(props.tip ? props.tip : "Loading your data, please wait...");    
    return (
    <div style={{paddingTop:props?.topPadding}}>
        <div className="blank-logo"/>
        <Spin className="spinner" tip={tip}/>
</div>);
};

export default LoadingSpinner;