import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = (frontendUrl = window.location.origin) => {
  if(frontendUrl.includes("www.leafgrc.com")){
    return {
      UserPoolId: 'eu-west-1_ooVAaVYdH',
      ClientId: '314kj8318id4cfgvh7ggrg1fr2',
    }
  }
  return {
    UserPoolId: 'eu-west-1_ooVAaVYdH',
    ClientId: '314kj8318id4cfgvh7ggrg1fr2',
  }
};
export default new CognitoUserPool(poolData());